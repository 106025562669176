<template>
	<div class="home">
		<div class="datatitle wirthbackgour">
			<div class="title_item" v-for="(item,index) in list" :key="index">
				<div class="icon_box" :style="[item.style]">
					<i :class="item.icon"></i>
				</div>
				<div class="cont">
					<div>{{item.name}}</div>
					<div>{{item.number | numberToCurrency}}</div>
				</div>
			</div>
		</div>
		
		<div class="box_echart">
			<div class="echarts_box">
				<div class="tb_box">
					<div class="inner_box wirthbackgour" style="padding: 0.1rem;">
						<div class="title" style="margin-right: 0;width: fit-content;height: fit-content;">委员注册率</div>
						<div style="width: 100%;height: 90%;display: flex;">
							<div id="main1" style="width:50%;height:100%;"></div>
							<div style="width: 50%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;">
								<div style="margin-bottom: 1vh;">小组情况</div>
								<div v-for="(item,index) in xzlist" :key="index" style="display: flex;align-items: center;margin: 0.5vh 0;">
									<div style="width:0.5rem;margin-right: 0.2rem;">{{item.name}}</div>
									<div style="width: 0.2rem;">{{item.number}}%</div>
								</div>
							</div>
						</div>
					</div>
					<div class="inner_box" style="padding: 0.1rem;">
						<div class="title" style="margin-right: 0;width: fit-content;height: fit-content;">委员年龄结构</div>
						<div id="main2" style="width:100%;height:90%;"></div>
					</div>
				</div>
				<div class="tb_box" style="padding: 0.1rem;">
					<div class="title" style="margin-right: 0;width: fit-content;height: fit-content;">委员端操作巅峰值段</div>
					<div id="main3" style="width:100%;height:90%;"></div>
				</div>
			</div>
			<div class="echarts_box">
				<div class="title">优秀委员提案数</div>
				<div class="line"></div>
				<div class="pm_item" v-for="(item,index) in pmlist" :key="index">
					<div class="avater_box">
						<div class="avater">
							{{getnumberone(item.name)}}
						</div>
						<div style="color: #fff;">
							{{item.name}}
						</div>
					</div>
					<div class="jdt">
						<div :style="[item.stylejdt]">
							{{item.number}}
						</div>
					</div>
					<i class="el-icon-trophy" :style="[item.color]"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				list:[
					{
						icon:'el-icon-user-solid',
						name:'委员总数（人）',
						number:20000,
						style:{
							backgroundColor: '#52c1f5'
						}
					},
					{
						icon:'el-icon-data-line',
						name:'注册委员数（人）',
						number:18000,
						style:{
							backgroundColor: '#fb6260'
						}
					},
					{
						icon:'el-icon-s-ticket',
						name:'人均提案数（个）',
						number:500,
						style:{
							backgroundColor: '#8167f5'
						}
					},
					{
						icon:'el-icon-office-building',
						name:'政界任职（人）',
						number:18000,
						style:{
							backgroundColor: '#4bced0'
						}
					},
					{
						icon:'el-icon-s-shop',
						name:'商界任职（人）',
						number:200000,
						style:{
							backgroundColor: '#58a3f7'
						}
					}
				],
				pmlist:[
					{
						name:'Keyll',
						number:100,
						stylejdt:{
							background:'#7ac756',
							width:"100%"
						},
						color:{
							color:'#7ac756'
						},
					},
					{
						name:'Shell',
						number:90,
						stylejdt:{
							background:'#e29836',
							width:"90%"
						},
						color:{
							color:'#e29836'
						},
					},
					{
						name:'Tom',
						number:83,
						stylejdt:{
							background:'#f26161',
							width:"83%"
						},
						color:{
							color:'#f26161'
						},
					},
					{
						name:'Jack',
						number:78,
						stylejdt:{
							background:'#7ac756',
							width:"78%"
						},
						color:{
							color:'#7ac756'
						},
					},
					{
						name:'Dorem',
						number:70,
						stylejdt:{
							background:'#e29836',
							width:"70%"
						},
						color:{
							color:'#e29836'
						},
					},
					{
						name:'Kore',
						number:68,
						stylejdt:{
							background:'#f26161',
							width:"68%"
						},
						color:{
							color:'#f26161'
						},
					}
				],
				xzlist:[
					{name:'第一小组',number:'75.8'},
					{name:'第二小组',number:'72'},
					{name:'第三小组',number:'68'},
					{name:'第四小组',number:'66'},
					{name:'第五小组',number:'60'}
				]
			}
		},
		mounted() {
			this.echart1()
			this.echart2()
			this.echart3()
		},
		computed:{
			getnumberone(){
				return function(str){
					return str.charAt(0)
				}
			}
		},
		methods: {
			echart1() {
				var myChart = echarts.init(document.getElementById('main1'))
				var option = {
					tooltip: {
					    trigger: 'item',
						valueFormatter: function(value) {
							return value + '%';
						}
					  },
					  color: ['#4bced0', '#d9d9d9'],
					  series: [
					    {
					      
					      type: 'pie',
					      radius: ['40%', '70%'],
					      avoidLabelOverlap: false,
					      label: {
					        show: false,
					        position: 'center',
							formatter: '{c}%'				
					      },
					      emphasis: {
					        label: {
					          show: true,
					          fontSize: '20',
					          fontWeight: 'bold'
					        }
					      },
					      labelLine: {
					        show: false
					      },
					      data: [
					        { value: 75.8, name: '已注册' },
					        { value: 24.2, name: '未注册' },
					      ]
					    }
					  ]
				}
				myChart.setOption(option)
			},
			echart2() {
				var myChart = echarts.init(document.getElementById('main2'))
				var option = {
					tooltip: {
					    trigger: 'item'
					  },
					color: ['#5087ec','#f2bd42','#fb5f5f','#1c7b3b','#ff1600'],
					  series: [
					    {
					      name: '',
					      type: 'pie',
					      radius: ['40%', '70%'],
						  label:{
							  formatter: '{b}\n {c}人',
						  },
					      data: [
					        { value: 100, name: '20~29岁' },
					        { value: 150, name: '30~39岁' },
					        { value: 136, name: '40~49岁' },
					        { value: 131, name: '50~59岁' },
					        { value: 145, name: '60岁以上' }
					      ],
					      itemStyle: {
					        borderRadius: 10,
					        borderColor: '#fff',
					        borderWidth: 2
					      },
					      emphasis: {
					        itemStyle: {
					          shadowBlur: 10,
					          shadowOffsetX: 0,
					          shadowColor: 'rgba(0, 0, 0, 0.5)'
					        }
					      }
					    }
					  ]
				}
				myChart.setOption(option)
			},
			echart3() {
				var myChart = echarts.init(document.getElementById('main3'))
				var option = {
					title: {
					    text: '浏览量(PV)',
					    left: 'center',
						textStyle:{
								color:"#fff"
							},
					  },
					  tooltip: {
					    trigger: 'axis'
						
					  },
					  legend: {
					    data: ['今天', '昨天', '7天前','30天前'],
					    bottom:'0',
						textStyle:{
								color:"#fff"
							},
					  },
					  grid: {
					    left: '3%',
					    right: '3%',
					    bottom: '14%',
						top:'14%',
					    containLabel: true
					  },
					  
					  xAxis: {
					    type: 'category',
					    boundaryGap: false,
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						},
					    data: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']
					  
					  },
					  yAxis: {
					    type: 'value',
						min: 0,
						max: 200,
						interval: 100,
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						},
					  },
					  series: [
					    {
					      name: '今天',
					      type: 'line',
					      data: ['','','','',5,10,20,30,20,28,50,70,'','','','','','','','','','','','']
					    },
					    {
					      name: '昨天',
					      type: 'line',
					      data: [20,1,'','','','',15,14,25,57,86,120,175,100,70,50,37,34,20,15,10,66,46,50]
					    },
					    {
					      name: '7天前',
					      type: 'line',
					      data: ['','','','','','','','',5,20,30,50,10,'',20,100,60,95,45,30,70,67,21,45]
					    },
						{
						  name: '30天前',
						  type: 'line',
						  data: []
						}
					  ]
				}
				myChart.setOption(option)
			}

		},

	}
</script>

<style lang="less" scoped>
	.box_echart{
		height: 67vh;
		width:90.5%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 2vh;
		.echarts_box{
			height: 100%;
			width: 68.5%;
			padding: 0.1rem;
			font-size: 0.1rem;
			.title {
				width: fit-content;
				color: rgb(11, 90, 245);
				border: 1px solid rgb(11, 90, 245);
				padding: 4px 0.2rem;
				border-radius: 5px;
				margin-right: 0.2rem;				
			}
			.line{
				margin-top: 1vh;
				height: 1px;
				width: 100%;
				background-color: #c0c0c0;
			}
			.pm_item{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1vh 0;
				.avater_box{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 10%;
					.avater{
						width: 0.2rem;
						height: 0.2rem;
						background-color: #f2b258;
						border-radius: 10rem;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
					}
				}
				.jdt{
					width: 80%;
					height: 2vh;
					background-color: #e0e3ea;
					border-radius: 1rem;
					position: relative;
					div{
						position: absolute;
						height: 100%;
						border-radius: 1rem;
						padding-right: 0.05rem;
						font-size: 0.08rem;
						color: #fff;
						text-align: right;
					}
				}
				i{
					font-size: 0.15rem;
					color: #f7975f;
				}
			}
		}
		.echarts_box:nth-last-child(1){
			border: 1px solid #c0c0c0;
			width: 30%;
			border-radius: 5px;
			box-shadow: 0 0 5px #c0c0c0;
		}
		.echarts_box:nth-child(1){
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 0;
			.tb_box{
				height: 48%;
				width: 100%;
				border: 1px solid #c0c0c0;
				border-radius: 5px;
				box-shadow: 0 0 5px #c0c0c0;
			}
			.tb_box:nth-child(1){
				border: none;
				box-shadow: none;
				display: flex;
				flex-direction: initial;
				justify-content: space-between;
				.inner_box{
					height: 100%;
					width: 49%;
					border: 1px solid #c0c0c0;
					border-radius: 5px;
					box-shadow: 0 0 5px #c0c0c0;
					
				}
			}
		}
	}
	.datatitle{
		width:90.5%;
		height: 15vh;
		border: 1px solid #c0c0c0;
		border-radius: 5px;
		box-shadow: 0 0 5px #c0c0c0;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-around;
		.title_item{
			display: flex;
			align-items: center;
			.icon_box{
				width: 0.45rem;
				height: 8vh;
				border-radius: 0.05rem;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 0.1rem;
				i{
					font-size: 0.25rem;
					color: #fff;
				}
			}
			.cont{
				display: flex;
				align-items: flex-start;
				justify-content: space-around;
				height: 8vh;
				flex-direction: column;
				font-size: 0.1rem;
				div:nth-last-child(1){
					font-weight: bold;
					font-size: 0.14rem;
				}
			}
		}
	}
	/deep/ .block{
		input{
			background: none !important;
		}
	}
</style>
